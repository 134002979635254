import React, {useState} from "react";

const LanguageInicio = () => {

    const [display, setDisplay] = useState(0);

    let visualizacion
    let radiusES
    let radiusEN

    if (display==1){
        visualizacion="flex"
        radiusES="0 0 15px 15px"
        radiusEN="15px 15px 0 0"
        
    } else{
        visualizacion="none"
    }
  
    return (

        <div onMouseLeave={()=>setDisplay(0)} className='language'>
            <button onClick={()=>setDisplay(1)} style={{borderRadius: (radiusEN)}} className="LanguageInicio" >
                EN<img className="bandera" src="../Imagenes/bandera.png"/>
                <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.33892 6.31999C1.77934 5.79567 0.900443 5.82397 0.375747 6.38355C-0.148959 6.94312 -0.120666 7.82202 0.438913 8.34672L4.8834 12.5133C5.47979 13.0721 6.42763 12.9981 6.92951 12.3525L14.7074 2.35263C15.1782 1.74693 15.0693 0.874276 14.4636 0.403448C13.8583 -0.0673898 12.9857 0.0415077 12.5148 0.647195L5.67189 9.44471L2.33892 6.31999Z" fill="#70D267"/>
                </svg>
            </button>
            <button style={{display: (visualizacion), borderRadius: (radiusES)}} className="buttonES" onClick={() => {window.location.href = `https://www.es.growi.fi`;setDisplay(0)}}>
                <span className="idiomaBoton">ES</span>
            </button>
        </div>
)
}

export default LanguageInicio;