const Caja1 = () => {

    return (
        <div className="Caja1">
            <svg className="imagen1" width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_805_16638)">
                <circle cx="32.5" cy="32.5002" r="32.5" fill="#F6BC91"/>
                <circle cx="32.5" cy="32.5002" r="28.0469" stroke="white" strokeOpacity="0.3" strokeWidth="8.90617"/>
                </g>
                <path d="M27.7078 41.0002V37.5682H30.8278V27.6322H27.9478V24.2002H34.7638V37.5682H37.5718V41.0002H27.7078Z" fill="#262626"/>
                <defs>
                <filter id="filter0_b_805_16638" x="-169.119" y="-169.118" width="403.237" height="403.237" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="84.5593"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_805_16638"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_805_16638" result="shape"/>
                </filter>
                </defs>
            </svg>
            <p className="titulo1">Simplicity</p>
            <p className="descripcion1">Just deposit the funds and forget about the process, no need to search through thousands of different pools and protocols, we do that for you</p>
        </div>
    )
}

export default Caja1;