import React from 'react';
import Inicio from './Inicio';
import { BrowserRouter, HashRouter, Link, Route, Routes, Switch } from "react-router-dom";

function App() {

    return(

      <div>
        <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Inicio />} />
            </Routes>
        </BrowserRouter>
      </div>
  
    )
  
  

}

export default App;