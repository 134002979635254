
const Support = () => {

    return (

        <div style={{width: 253, height: 192}} className="Support">
            <p className="titulo">Need help?</p>
            <ul className="lista">
                <li>
                    <svg style={{marginTop: 5, position: "absolute"}} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke="#262626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <a className="enlacesFooter" href="https://docs.growi.fi/" target="_blank"> Docs</a>
                </li>
                <li>
                    <svg style={{marginTop: 5, position: "absolute"}} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke="#262626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <a className="enlacesFooter" href="" target="_blank"> Step by Step Guides (WIP)</a>
                </li>
                <li>
                    <svg style={{marginTop: 5, position: "absolute"}} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke="#262626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <a className="enlacesFooter" href="" target="_blank"> FAQs (WIP)</a>
                </li>
                <li>
                    <svg style={{marginTop: 5, position: "absolute"}} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke="#262626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <a className="enlacesFooter" href="" target="_blank"> Video tutorials (WIP)</a>
                </li>
            </ul>
        </div>

)
}

export default Support;